
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {defineComponent, onMounted} from "vue";
import {setCurrentPageButtons} from "@/core/helpers/page-buttons";
import DataService from "@/core/services/DataService";
import OfferSelector from '@/components/selectors/OfferSelector.vue';
import NextDateRangePicker from '@/components/datetime/NextDateRangePicker.vue';
import moment from "moment";
import Entity from '@/components/common/Entity.vue';

export default defineComponent({
	name: 'conversions',
	components: {
		OfferSelector, NextDateRangePicker, Entity
	},
	setup() {
		onMounted(() => {
			setCurrentPageBreadcrumbs('Conversions', ['Reports']);
			setCurrentPageButtons(null, []);
		});
	},
	data() {
		return {
			busy: false,
			records: [],
			filters: {
				// keyword: '',
				dates: {
					timezone: 0,
					dateRange: null
				},
				offer_id: [],
			},
			pagination: {
				page: 1,
				page_size: 20,
				total: 0
			},
		};
	},
	methods: {
		entityClicked(v: {type: string, id: number}): void {
            if (['offer'].indexOf(v.type) === -1){
                return;
            }
            const filtersFieldName = v.type + '_id';
            const filtersFieldValue: number[] = this.filters[filtersFieldName];
            filtersFieldValue.push(v.id);
            const uniqueSet = new Set(filtersFieldValue);
            this.filters[filtersFieldName] = Array.from(uniqueSet);
        },
		getListPayload(format = "json") {
			if (!this.filters.dates || !this.filters.dates.dateRange){
				return null;
			}
			return {
				keyword: this.filters.keyword,
				page: this.pagination.page,
				limit: this.pagination.page_size,
				out_format: format,
				date_start: moment(this.filters.dates.dateRange[0]).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.dates.dateRange[1]).format('YYYY-MM-DD HH:mm'),
				timezone: this.filters.dates.timezone
			}
		},
		async loadData(){
			if (this.busy){
				return;
			}
			try {
				let data = {
					keyword: this.filters.keyword,
					offer_id: this.filters.offer_id,
					page: this.pagination.page,
					limit: this.pagination.page_size,
					date_start: moment(this.filters.dates.dateRange[0]).format('YYYY-MM-DD HH:mm'),
					date_end: moment(this.filters.dates.dateRange[1]).format('YYYY-MM-DD HH:mm'),
					timezone: this.filters.dates.timezone
				};
				this.busy = true;
				let resp = await DataService.get('conversions/getList', data);
				this.records = resp.records;
				this.pagination.total = resp.total;
			} catch(e: any) {
				console.error(e);
			} finally {
				this.busy = false;
			}
		},
		handleChange(record, e) {
            record[e.target.name] = e.target.value;
        },
	},
	mounted() {
		this.loadData();
	},
});
